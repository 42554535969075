<template>
  <div class="TipsNews">
    <top-bar title="我的户号" color="#FFF" class="myuser"></top-bar>
    <div class="payment_top flx flex-align-center">
      <img src="../../assets/huhao/icon2.png" alt=""/>
      <div>京源中科</div>
    </div>
    <section class="order-index-wrapper" v-for="(item, index) in list" :key="index">
      <div class="order-index-list">
        <section class="order-all">
          <div class="flex start">
            <div>
              <!-- 头像 -->
              <van-icon class="avatar" name="manager-o"/>
            </div>
            <div class="grow">
              <div class="info">
                <div class="flex">
                  <p class="greater grow">{{ item.guestName }}</p>
                </div>
                <p class="guest-no weaker">户号：{{ item.guestNo }}</p>
                <div class="buttom-flex">
                  <button class="to-pay" @click="unbind(item.bindId)">解绑</button>
                  <div>
                    <button class="to-pay" v-if="item.defaultBinder == 1" style="background-color: #7d7e80">当前用户
                    </button>
                    <button class="to-pay" v-else-if="item.defaultBinder == 0" style="background-color: forestgreen"
                            @click="switchUser(item.bindId)">切换用户
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'
import {userIdCache} from '@/assets/js/cache'

export default {
  name: 'WaterPayment',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      list: [],
      isCurrent: true
    }
  },
  watch: {},
  created() {
    this.getList()
  },
  methods: {
    getList() {
      ajax.getAllAccount({
        userId: userIdCache.getCache(),
      }).then((res) => {
        if (res.data instanceof Array) {
          this.list = res.data || []
        }
      })
    },
    switchUser(bindId) {
      ajax.switchAccount({
        userId: userIdCache.getCache(),
        bindId: bindId
      }).then((res) => {
        this.$toast.success(res.msg)
        this.getList()
      })
    },
    unbind(bindId) {
      this.$dialog.confirm({
        message: '确认解绑该户号？',
      }).then(() => {
        const params = {
          bindId: bindId,
          userId: userIdCache.getCache(),
        }
        ajax.unboundAccount(params).then(res => {
          if (res.code == 200) {
            this.$toast.success(res.msg)
            this.getList()
          } else {
            this.$toast.fail(res.msg)
          }
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./style.scss";

.buttom-flex {
  display: flex;
  padding-top: 9px;

  button {
    width: 80px;
    height: 33px;
  }

  :first-child {
    margin-right: 18px;
  }
}
</style>

